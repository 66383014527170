import axios from "axios";
import { createContext } from "react";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";

export const initGA = (id) => {
//   if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
//   }
};

export const RequestFormContext = createContext({
    
});

export const download = async (url) =>{
    try{
        const response = await axios.get(url,{ responseType: 'blob' })
        const responseUrl = window.URL
        .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = responseUrl;
        link.setAttribute('download', url.split('/').pop());
        document.body.appendChild(link);
        link.click();
    }
    catch(e){
        console.log("this is getting wrong::",e)
    }
    // axios({
    //     url:url,
    //     method:'GET',
    //     responseType: 'blob'
    // })
    // .then((response) => {
    //     const url = window.URL
    //     .createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'image.jpg');
    //     document.body.appendChild(link);
    //     link.click();
    // })
}
