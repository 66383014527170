import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(
        function () {
            const path = location.pathname + location.search;
            //ReactGA.set({ page: path });
            //ReactGA.pageview(path); // Record a pageview for the given page
            ReactGA.send({ hitType: "pageview", page: path });
            console.log("loew")
        },
        [location],
    );
    return '';
};


export default RouteChangeTracker